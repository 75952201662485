/**
 * @file Detailed Information of an event
 * @author Alwyn Tan
 */

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTable } from 'react-table'
import useUnregisterEvent from 'hooks/query/useUnregisterEvent'
import toast from 'react-hot-toast'

const Container = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px;
`

const Table = styled.table`
  box-sizing: border-box;
  border-collapse: collapse;
`

const THead = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.Text}26;
`

const TR = styled.tr`
  background-color: ${({ theme, highlight }) =>
    highlight ? `${theme.Text}1A` : 'unset'};

  td:first-child {
    border-radius: 10px 0 0 10px;
  }

  td:last-child {
    border-radius: 0 10px 10px 0;
  }
`

const TD = styled.td`
  padding: 10px;

  :first-child {
    border-radius: 10px 0 0 10px;
  }

  :last-child {
    border-radius: 0 10px 10px 0;
  }
`

const ActionButton = styled.h4`
  color: ${({ theme }) => theme.Accent};
  cursor: pointer;
  user-select: none;
`

const EventDetailedTable = ({ id, participants, extraSeatCodes }) => {
  const { mutateAsync, isLoading } = useUnregisterEvent()

  const handleRemoveUser = useCallback(
    async userID => {
      if (!isLoading) {
        const toastID = toast.loading('Unregistering User')
        try {
          await mutateAsync({ eventID: id, userID })
          toast.success('Unregistered user', { id: toastID })
        } catch (e) {
          toast.error('Error unregistering user', { id: toastID })
        }
      }
    },
    [id, isLoading, mutateAsync]
  )

  const [columns, data] = useMemo(() => {
    const cols = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value }) => (value ? String(value) : '-'),
      },
      {
        Header: 'Phone',
        accessor: 'phoneNumber',
        Cell: ({ value }) => (value ? String(value) : '-'),
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => (value ? String(value) : '-'),
      },
      {
        Header: 'Bought by',
        accessor: 'boughtBy',
        Cell: ({ value }) => (value ? String(value) : '-'),
      },
      {
        Header: 'User ID',
        accessor: 'id',
        Cell: ({ value }) => (value ? String(value) : '-'),
      },
      {
        Header: 'Actions',
        accessor: row => row.id,
        Cell: ({ value }) => {
          if (value)
            return (
              <ActionButton onClick={() => handleRemoveUser(value)}>
                Remove
              </ActionButton>
            )
          return '-'
        },
      },
    ]

    // combine redeemed with a user
    const boughtByMap = extraSeatCodes?.reduce((acc, curr) => {
      if (curr.status === 'REDEEMED')
        return { ...acc, [curr.redeemedBy.id]: curr.user }
      return acc
    }, {})

    const parsedData = [
      ...participants.map(p => ({
        ...p,
        boughtBy: boughtByMap?.[p.id]?.name,
      })),
      ...extraSeatCodes?.reduce((acc, curr) => {
        if (curr.status === 'ACTIVE')
          return [
            ...acc,
            { extraCodeStatus: curr.status, boughtBy: curr.user.name },
          ]
        return acc
      }, []),
    ]

    return [cols, parsedData]
  }, [participants, extraSeatCodes, handleRemoveUser])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  return (
    <Container>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{ textAlign: 'left', padding: '10px' }}
                >
                  <h3>{column.render('Header')}</h3>
                </th>
              ))}
            </tr>
          ))}
        </THead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TR
                {...row.getRowProps()}
                highlight={row.original.extraCodeStatus === 'ACTIVE'}
              >
                {row.cells.map(cell => (
                  <TD {...cell.getCellProps()} style={{ padding: '10px' }}>
                    {cell.render('Cell') || '-'}
                  </TD>
                ))}
              </TR>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

EventDetailedTable.propTypes = {
  id: PropTypes.string.isRequired,
  participants: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  extraSeatCodes: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default EventDetailedTable
