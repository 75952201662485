/**
 * @file Superpod Details
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Card from 'components/simple/Card'

const StyledCard = styled(Card)`
  padding: 40px 20px;
  width: 335px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > h4 {
    margin-bottom: 10px;
  }
`

const Picture = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 40px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ src }) => src});
  margin-bottom: 20px;
`

const SuperpodDetailsCard = ({ picture, displayName, name, description }) => (
  <StyledCard>
    <Picture src={picture} />
    <h2>{displayName}</h2>
    <h4>{name}</h4>
    <p>{description}</p>
  </StyledCard>
)

SuperpodDetailsCard.propTypes = {
  picture: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default SuperpodDetailsCard
