/**
 * @file Hook to retrieve all active events
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useInfiniteQuery } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const GET_ACTIVE_EVENTS_URL = '/api/event/active'

const getActiveEvents = async (lastLoadedID, accessToken) => {
  const suffix = lastLoadedID
    ? `?lastLoadedID=${encodeURIComponent(lastLoadedID)}`
    : ''
  const { events: data, canLoadMore } = await get(
    `${GET_ACTIVE_EVENTS_URL}${suffix}`,
    {
      accessToken,
    }
  )

  return { data, canLoadMore }
}

const useGetActiveEvents = () => {
  const { auth } = useContext(RootContext)
  return useInfiniteQuery(
    'events-active',
    async ({ pageParam }) => getActiveEvents(pageParam, auth.accessToken),
    {
      getNextPageParam: lastPage => {
        if (lastPage.canLoadMore)
          return lastPage.data[lastPage.data.length - 1].id
        return undefined
      },
    }
  )
}

export default useGetActiveEvents
