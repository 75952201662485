/**
 * @file Page Template
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Sidebar from 'components/compound/Sidebar'
import Header from 'components/compound/Header'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const MainContainer = styled.div`
  position: relative;
  padding: 0px 30px 0 40px;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const ChildContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding-top: 15px;
  overflow: hidden;
  margin-left: -10px;
`

const Page = ({ children, style }) => (
  <Container>
    <Sidebar />
    <MainContainer>
      <Header />
      <ChildContainer style={style}>{children}</ChildContainer>
    </MainContainer>
  </Container>
)

Page.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

Page.defaultProps = { style: {} }

export default Page
