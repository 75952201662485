/**
 * @file Auth Phone Screen
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import useRequestLogin from 'hooks/query/useRequestLogin'
import PhoneInput from 'components/simple/PhoneInput'
import Logo from 'components/simple/Logo'

const Form = styled.form`
  width: 100%;
  max-width: 335px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    margin-bottom: 15px;
  }
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.Accent};
  outline: none;
  border: 0;
  border-radius: 10px;
  color: white;
  font-size: 1em;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const Footer = styled.h6`
  padding-top: 40px;
  opacity: 0.5;
  text-align: center;
`

const AuthPhoneScreen = ({ onSubmit: onSubmitProp, defaultPhoneNumber }) => {
  const { control, formState, handleSubmit } = useForm({ mode: 'onChange' })

  const mutation = useRequestLogin()

  const onSubmit = async ({ phoneNumber: formPhoneNumber }) => {
    mutation.mutate(formPhoneNumber)
  }

  useEffect(() => {
    if (mutation.isSuccess) onSubmitProp(mutation.variables)
  }, [mutation.isSuccess, mutation.variables, onSubmitProp])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Logo />
      <h2>Log In</h2>
      <PhoneInput
        control={control}
        name="phoneNumber"
        placeholder="Enter Phone Number"
        defaultValue={defaultPhoneNumber}
        required
      />
      <Button disabled={!formState.isValid || mutation.isLoading}>
        <h3>Next</h3>
      </Button>
      <Footer>Solo © 2020</Footer>
    </Form>
  )
}

AuthPhoneScreen.propTypes = {
  defaultPhoneNumber: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

AuthPhoneScreen.defaultProps = {
  defaultPhoneNumber: '',
}

export default AuthPhoneScreen
