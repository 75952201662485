/**
 * @file Table to display the superpod members
 * @author Alwyn Tan
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTable } from 'react-table'

const Container = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px;
`

const Table = styled.table`
  box-sizing: border-box;
  border-collapse: collapse;
`

const THead = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.Text}26;
`

const TR = styled.tr`
  background-color: ${({ theme, highlight }) =>
    highlight ? `${theme.Text}1A` : 'unset'};

  td:first-child {
    border-radius: 10px 0 0 10px;
  }

  td:last-child {
    border-radius: 0 10px 10px 0;
  }
`

const TD = styled.td`
  padding: 10px;

  :first-child {
    border-radius: 10px 0 0 10px;
  }

  :last-child {
    border-radius: 0 10px 10px 0;
  }
`

const SuperpodMembersTable = ({ users }) => {
  const [columns, data] = useMemo(() => {
    const cols = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value }) => (value ? String(value) : '-'),
      },
      {
        Header: 'Phone',
        accessor: 'phoneNumber',
        Cell: ({ value }) => (value ? String(value) : '-'),
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => (value ? String(value) : '-'),
      },
      {
        Header: 'Profession',
        accessor: 'profession',
        Cell: ({ value }) => (value ? String(value) : '-'),
      },
      {
        Header: 'User ID',
        accessor: 'id',
      },
    ]

    return [cols, users]
  }, [users])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  return (
    <Container>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{ textAlign: 'left', padding: '10px' }}
                >
                  <h3>{column.render('Header')}</h3>
                </th>
              ))}
            </tr>
          ))}
        </THead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TR
                {...row.getRowProps()}
                highlight={row.original.extraCodeStatus === 'ACTIVE'}
              >
                {row.cells.map(cell => (
                  <TD {...cell.getCellProps()} style={{ padding: '10px' }}>
                    {cell.render('Cell') || '-'}
                  </TD>
                ))}
              </TR>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

SuperpodMembersTable.propTypes = {
  users: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default SuperpodMembersTable
