/**
 * @file Search Bar
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Search } from 'react-feather'

const Container = styled.div`
  height: 40px;
  width: 250px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.Secondary};
  border-radius: 20px;
  padding: 0 15px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  > input {
    background-color: white;
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    padding-left: 10px;
  }
`

// todo: finish up the implementation of search before turning this on
const SearchBar = () => (
  <Container>
    <Search size={22} />
    <input placeholder="Search (Coming Soon)" />
  </Container>
)

export default SearchBar
