/**
 * @file Request Login Hook
 * @author Alwyn Tan
 */

import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import { post } from 'utils/api'

const REQUEST_LOGIN_URL = '/api/auth/login-request?admin=true'

const postRequestLogin = async phoneNumber => {
  const { success, error } = await post(
    REQUEST_LOGIN_URL,
    { phoneNumber },
    { includeCredentials: true }
  )

  if (success) {
    toast.success(`SMS Code sent to ${phoneNumber}`)
  }

  if (error) {
    const e =
      error || 'Sorry, there appears to be a problem. Please try again later.'
    toast.error(e)
    throw new Error(e)
  }

  return { success }
}

const useRequestLogin = () =>
  useMutation(phoneNumber => postRequestLogin(phoneNumber))

export default useRequestLogin
