/**
 * @file Events
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import InfiniteScroll from 'components/compound/InifiniteScroll'
import useGetActiveEvents from 'hooks/query/useGetActiveEvents'
import ActiveEventCard from 'components/compound/Events/Cards/ActiveEventCard'

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  padding: 0px 10px 8px 10px;
`

const Events = () => (
  <>
    <Column>
      <Title>Active Events</Title>
      <InfiniteScroll
        style={{ padding: '2px 10px 0 10px' }}
        infiniteQueryHook={useGetActiveEvents}
        endOfListComponent={
          <p style={{ opacity: 0.5, paddingTop: 20, textAlign: 'center' }}>
            No more active events to show 👾
          </p>
        }
      >
        {item => (
          <ActiveEventCard
            key={item.id}
            id={item.id}
            startDate={item.startDate}
            title={item.title}
            picture={item.picture}
            seatsLeft={item.seatsLeft}
            maxParticipants={item.maxParticipants}
          />
        )}
      </InfiniteScroll>
    </Column>
  </>
)

Events.propTypes = {}

export default Events
