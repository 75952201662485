/**
 * @file Rejects superpod verification
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const POST_REJECT_SUPERPOD_VERIFICATION_URL =
  '/api/verification/reject-superpod'

const postRejectSuperpodVerification = async (id, accessToken) =>
  post(POST_REJECT_SUPERPOD_VERIFICATION_URL, { id }, { accessToken })

const useRejectSuperpodVerification = () => {
  const { auth } = useContext(RootContext)
  const queryClient = useQueryClient()

  // defaults party host to the auth, unless specified
  return useMutation(async id => {
    const toastID = toast.loading('Rejecting Verification')
    const { verification } = await postRejectSuperpodVerification(
      id,
      auth.accessToken
    )

    if (verification.status === 'REJECTED') {
      toast.success('Verification Rejected', { id: toastID })
      queryClient.invalidateQueries('pods-pending-verification')
    } else {
      toast.error('Error rejecting verification', { id: toastID })
    }

    return { verification }
  })
}

export default useRejectSuperpodVerification
