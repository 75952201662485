/**
 * @file Superpod
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Card from 'components/simple/Card'
import Button from 'components/simple/Button'
import { useHistory } from 'react-router-dom'

const StyledCard = styled(Card)`
  padding: 20px;
  width: 260px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const InfoContainer = styled.div`
  flex: 1;
  margin-right: 10px;

  > h4 {
    opacity: 0.5;
  }
`

const SuperpodCard = ({ id, displayName, name }) => {
  const history = useHistory()

  const handleViewClick = () => history.push(`/pods/${id}`)

  return (
    <StyledCard>
      <InfoContainer>
        <h3>{displayName}</h3>
        <h4>{name}</h4>
      </InfoContainer>
      <Button size="small" onClick={handleViewClick}>
        View
      </Button>
    </StyledCard>
  )
}

SuperpodCard.propTypes = {
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default SuperpodCard
