/**
 * @file Card Container
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
`

const Card = ({ children, style, className, onClick }) => (
  <Container className={className} style={style} onClick={onClick}>
    {children}
  </Container>
)

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

Card.defaultProps = {
  className: '',
  onClick: () => {},
  style: {},
}

export default Card
