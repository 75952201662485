/**
 * @file Event Details Card
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Calendar, Clock, MapPin, Tag } from 'react-feather'
import dayjs from 'dayjs'
import Card from 'components/simple/Card'

const Container = styled(Card)`
  width: 335px;
  border-radius: 10px;
`

const CoverImage = styled.div`
  height: 225px;
  width: 335px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ src }) => src});
`

const Content = styled.div`
  padding: 20px;
`

const Info = styled.div`
  display: flex;
  margin: 10px 0;

  > *:first-child {
    min-width: 22px;
  }

  > h4 {
    padding-left: 10px;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.Text}26;
  margin: 20px 0;
`

const ParticipantsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Participant = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 10px;
  margin-top: 10px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ src }) => src});
`

const HostInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const HostPicture = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ src }) => src});
  border-radius: 28px;
  width: 56px;
  height: 56px;
`

const EventDetailsCard = ({
  picture,
  title,
  location,
  about,
  date,
  time,
  price,
  participants,
  host,
}) => (
  <Container>
    <CoverImage src={picture} />
    <Content>
      <h2>{title}</h2>
      <Info>
        <MapPin size={22} />
        <h4>{location}</h4>
      </Info>
      <Info>
        <Calendar size={22} />
        <h4>{date}</h4>
      </Info>
      <Info>
        <Clock size={22} />
        <h4>{time}</h4>
      </Info>
      <Info>
        <Tag size={22} />
        <h4>{`$${(price / 100).toFixed(2)}` || 'Free'}</h4>
      </Info>
      <Divider />
      <h3 style={{ marginBottom: 5 }}>About</h3>
      <p>{about}</p>
      <Divider />
      <h3 style={{ marginBottom: 5 }}>Who's Coming</h3>
      <ParticipantsContainer>
        {participants.map(p => (
          <Participant key={p.id} src={p.picture} />
        ))}
      </ParticipantsContainer>
      <Divider />
      <HostInfoContainer>
        <div>
          <h3 style={{ paddingBottom: 2 }}>Hosted by {host.name}</h3>
          <p>Joined {dayjs(host.joined).format('MMMM YYYY')}</p>
        </div>
        <HostPicture src={host.picture} />
      </HostInfoContainer>
      <p style={{ paddingTop: 20 }}>{host.host_info}</p>
    </Content>
  </Container>
)

EventDetailsCard.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  picture: PropTypes.string,
  about: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  price: PropTypes.number,
  participants: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  host: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

EventDetailsCard.defaultProps = {
  picture: '',
  price: null,
}

export default EventDetailsCard
