/**
 * @file Event Details Page
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import EventDetailsCard from 'components/compound/Events/Cards/EventDetailsCard'
import useGetEvent from 'hooks/query/useGetEvent'
import dayjs from 'dayjs'
import { Copy, Plus } from 'react-feather'
import EventDetailedTable from 'components/compound/Events/EventDetailedTable'
import Card from 'components/simple/Card'
import toast from 'react-hot-toast'
import AddUserModal from 'components/compound/Events/Modals/AddUserModal'

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  padding: 0px 10px 8px 10px;
`

const ScrollView = styled.div`
  flex: 1;
  overflow: auto;
  padding: 2px 10px 0 10px;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  margin: 10px 0 30px 0;
`

const ActionCard = styled(Card)`
  padding: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  user-select: none;

  svg {
    margin-right: 10px;
  }
`

const EventDetails = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const { id } = useParams()
  const { data } = useGetEvent(id)
  const event = data?.event

  if (!event) return null

  const handleCopyEmailsClick = () => {
    const emails = event.participants
      .reduce((acc, p) => (p.email ? [...acc, p.email] : acc), [])
      .join(', ')
    navigator.clipboard.writeText(emails)
    toast.success('Copied User Emails!')
  }

  const handleCopyPhoneClick = () => {
    const phoneNumbers = event.participants.map(p => p.phoneNumber).join(', ')
    navigator.clipboard.writeText(phoneNumbers)
    toast.success('Copied User Phone Numbers!')
  }

  const handleAddAUserClick = () => setShowAddUserModal(true)

  return (
    <>
      {showAddUserModal && (
        <AddUserModal onClose={() => setShowAddUserModal(false)} eventID={id} />
      )}
      <Column>
        <Title>Event Details</Title>
        <ScrollView>
          <EventDetailsCard
            picture={event.picture}
            title={event.title}
            about={event.about}
            location={event?.locationV2?.formatted || event.location}
            date={dayjs(event.startDate).format('D MMMM YYYY')}
            time={`${dayjs(event.startDate).format('h:mma')} - ${dayjs(
              event.endDate
            ).format('h:mma')}`}
            price={event.price}
            participants={event.participants}
            host={event.host}
          />
          <div style={{ minHeight: 40 }} />
        </ScrollView>
      </Column>
      <Column style={{ marginLeft: 30, flex: 1, width: 0 }}>
        <Title style={{ paddingBottom: 0 }}>Actions</Title>
        <ActionsContainer>
          <ActionCard onClick={handleCopyEmailsClick}>
            <h4>
              <Copy size={22} />
              Copy User Emails
            </h4>
          </ActionCard>
          <ActionCard onClick={handleCopyPhoneClick}>
            <h4>
              <Copy size={22} />
              Copy User Phone Numbers
            </h4>
          </ActionCard>
          <ActionCard onClick={handleAddAUserClick}>
            <h4>
              <Plus size={22} />
              Add a User
            </h4>
          </ActionCard>
        </ActionsContainer>
        <Title style={{ paddingBottom: 0 }}>Detailed View</Title>
        <h4 style={{ padding: '0px 10px 8px 10px' }}>
          {event.maxParticipants - event.seatsLeft}/{event.maxParticipants}{' '}
          seats {event.seatsLeft === 0 ? '(Full)' : ''}
        </h4>
        <ScrollView>
          <EventDetailedTable
            id={event.id}
            participants={event.participants}
            extraSeatCodes={event.extraSeatCodes}
          />
        </ScrollView>
      </Column>
    </>
  )
}

export default EventDetails
