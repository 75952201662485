/**
 * @file Modal Component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import Card from 'components/simple/Card'

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px) brightness(60%);
`

const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.Primary};
`

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const Modal = ({ style, className, children, onCancel }) => (
  <>
    <GlobalStyle />
    <FullScreenContainer onClick={onCancel}>
      <StyledCard
        style={style}
        className={className}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </StyledCard>
    </FullScreenContainer>
  </>
)

Modal.propTypes = {
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func, // eslint-disable-line react/forbid-prop-types
}

Modal.defaultProps = {
  style: {},
  className: null,
  onCancel: () => {},
}

export default Modal
