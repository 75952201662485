/**
 * @file Removes a user from an event
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const REGISTER_EVENT_URL = '/api/event/register-event'

const postRegisterEvent = async (eventID, userID, accessToken) =>
  post(REGISTER_EVENT_URL, { eventID, userID }, { accessToken })

const useRegisterEvent = () => {
  const { auth } = useContext(RootContext)
  const queryClient = useQueryClient()

  // defaults party host to the auth, unless specified
  return useMutation(async ({ eventID, userID }) => {
    const { event } = await postRegisterEvent(eventID, userID, auth.accessToken)
    queryClient.invalidateQueries(['event', eventID])
    return { event }
  })
}

export default useRegisterEvent
