/**
 * @file Auth Page
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import LoginImage from 'images/login.jpg'
import AuthPhoneScreen from 'components/compound/AuthPhoneScreen'
import AuthCodeScreen from 'components/compound/AuthCodeScreen'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`

const LoginContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
`

const SideImage = styled.div`
  height: 100%;
  width: 45%;
  background-position: center;
  background-size: cover;
  background-image: url(${({ src }) => src});
`

const Auth = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [authPageIndex, setAuthPageIndex] = useState(0)

  const handlePhoneModalSubmit = number => {
    setPhoneNumber(number)
    setAuthPageIndex(authPageIndex + 1)
  }

  const handleCodeModalSubmit = () => {
    // setAuthPageIndex(authPageIndex + 1)
  }

  const loginScreens = [
    <AuthPhoneScreen
      onSubmit={handlePhoneModalSubmit}
      defaultPhoneNumber={phoneNumber}
    />,
    <AuthCodeScreen
      onSubmit={handleCodeModalSubmit}
      phoneNumber={phoneNumber}
    />,
  ]

  return (
    <Container>
      <LoginContainer>{loginScreens[authPageIndex]}</LoginContainer>
      <SideImage src={LoginImage} />
    </Container>
  )
}

export default Auth
