/**
 * @file Looks up a user input
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AsyncSelect from 'react-select/async'
import useFindUser from 'hooks/query/user/useFindUser'
import { debounce } from 'lodash'
import { useController } from 'react-hook-form'

const Container = styled.div`
  width: 335px;
`

const Preview = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;

  > p {
    flex: 1;
    padding: 0 15px;
  }
`

const PreviewProfilePicture = styled.div`
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ src }) => src});
`

const PreviewRemoveButton = styled.h4`
  color: ${({ theme }) => theme.Accent};
  user-select: none;
`

const StyledSelect = styled(AsyncSelect)`
  * {
    color: ${({ theme }) => theme.Text} !important;
  }

  .react-select__placeholder {
    opacity: 0.2;
  }

  .react-select__control {
    height: 50px;
    background-color: ${({ theme }) => theme.Secondary};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border: 0;
    border-radius: 10px;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    white-space: nowrap;
  }

  .react-select__value-container {
    height: 100%;
    padding-left: 12px;
  }

  .react-select__indicators {
    display: none;
  }

  .react-select__input {
    height: 100%;

    > input {
      margin-bottom: 0;
    }
  }

  .react-select__menu {
    margin-top: 5;
    background-color: ${({ theme }) => theme.Secondary};

    .react-select__option--is-focused,
    .react-select__option:focus,
    .react-select__option:hover,
    .react-select__option:active {
      background-color: ${({ theme }) => theme.Tertiary};
    }
  }
`

const LookupUserInput = ({
  name,
  control,
  defaultValue,
  required,
  placeholder,
}) => {
  const findUserMutation = useFindUser()

  const { field } = useController({
    name,
    control,
    defaultValue,
    rules: { required },
  })

  const loadOptions = debounce(async (input, callback) => {
    const response = await findUserMutation.mutateAsync({ name: input })
    const options = response?.users?.map(user => ({
      user,
      label: user.name,
    }))
    callback(options)
  }, 250)

  const handleResetField = () => {
    field.onChange()
  }

  return (
    <Container>
      {field.value ? (
        <Preview>
          <PreviewProfilePicture src={field.value.picture} />
          <p>{field.value.name}</p>
          <PreviewRemoveButton onClick={handleResetField}>
            Remove
          </PreviewRemoveButton>
        </Preview>
      ) : (
        <StyledSelect
          cacheOptions
          classNamePrefix="react-select"
          loadOptions={loadOptions}
          placeholder={placeholder}
          {...field}
          onChange={v => field.onChange(v.user)}
          value={
            field.value ? { user: field.value, label: field.value?.name } : ''
          }
        />
      )}
    </Container>
  )
}

LookupUserInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
}

LookupUserInput.defaultProps = {
  defaultValue: '',
  required: false,
  placeholder: 'Enter a Name',
}

export default LookupUserInput
