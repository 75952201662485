/**
 * @file Sidebar Component
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Logo from 'components/simple/Logo'
import { Calendar, Home, LogOut, Users } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'
import { get } from 'utils/api'

const Container = styled.div`
  height: 100%;
  min-width: 80px;
  width: 80px;
  background-color: ${({ theme }) => theme.Secondary};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #ebeef8;
`

const Icons = styled.div`
  padding-top: 40px;
  width: 100%;
  flex: 1;

  > * {
    margin-bottom: 30px;
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme, active }) => (active ? theme.Accent : theme.Text)};
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ::after {
    position: absolute;
    right: 0;
    content: '';
    height: 150%;
    width: 4px;
    border-radius: 4px 0 0 4px;
    display: ${({ active }) => (active ? 'inline-block' : 'none')};
    background-color: ${({ theme }) => theme.Accent};
  }
`

const LogOutButton = styled(LogOut)`
  margin-bottom: 30px;
  cursor: pointer;
`

const Sidebar = () => {
  const location = useLocation()

  const handleLogout = async () => {
    await get('/api/auth/logout', { includeCredentials: true })
    window.location.reload()
  }

  return (
    <Container>
      <Logo style={{ height: 30, width: 44, marginTop: 20 }} />
      <Icons>
        <StyledLink to="/" active={location.pathname === '/' ? 1 : 0}>
          <Home />
        </StyledLink>
        <StyledLink
          to="/events"
          active={location.pathname.startsWith('/events') ? 1 : 0}
        >
          <Calendar />
        </StyledLink>
        <StyledLink
          to="/pods"
          active={location.pathname.startsWith('/pods') ? 1 : 0}
        >
          <Users />
        </StyledLink>
      </Icons>
      <LogOutButton onClick={handleLogout}>
        <p>Logout</p>
      </LogOutButton>
    </Container>
  )
}

export default Sidebar
