/**
 * @file Hook to retrieve all active events
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useInfiniteQuery } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const GET_PENDING_SUPERPOD_VERIFICATIONS_URL =
  '/api/verification/pending-superpods'

const getPendingSuperpodVerifications = async (lastLoadedID, accessToken) => {
  const suffix = lastLoadedID
    ? `?lastLoadedID=${encodeURIComponent(lastLoadedID)}`
    : ''
  const { verifications: data, canLoadMore } = await get(
    `${GET_PENDING_SUPERPOD_VERIFICATIONS_URL}${suffix}`,
    { accessToken }
  )

  return { data, canLoadMore }
}

const useGetPendingSuperpodVerifications = () => {
  const { auth } = useContext(RootContext)
  return useInfiniteQuery(
    'pods-pending-verification',
    async ({ pageParam }) =>
      getPendingSuperpodVerifications(pageParam, auth.accessToken),
    {
      getNextPageParam: lastPage => {
        if (lastPage.canLoadMore)
          return lastPage.data[lastPage.data.length - 1].id
        return undefined
      },
    }
  )
}

export default useGetPendingSuperpodVerifications
