/**
 * @file A simple implementation of an infinite scroll element
 * @author Alwyn Tan
 */

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  flex: 1;
  overflow: auto;
`

const InfiniteScroll = ({
  children,
  loadingComponent,
  endOfListComponent,
  infiniteQueryHook,
  infiniteQueryHookParams,
  onChange,
  style,
}) => {
  const infiniteTriggerRef = useRef(null)

  const { fetchNextPage, data, hasNextPage, isFetchingNextPage, isLoading } =
    infiniteQueryHook(...infiniteQueryHookParams)

  useEffect(() => {
    if (hasNextPage && infiniteTriggerRef.current) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => entry.isIntersecting && fetchNextPage()),
        { rootMargin: '0px', threshold: 1.0 }
      )

      const el = infiniteTriggerRef.current

      observer.observe(el)
      return () => observer.unobserve(el)
    }
    return () => {}
  }, [data?.pages, hasNextPage, fetchNextPage])

  useEffect(() => {
    onChange(data?.pages)
  }, [data?.pages, onChange])

  return (
    <Container style={style}>
      {children &&
        (data?.pages || []).map(page => page.data.map(item => children(item)))}
      {(isLoading || isFetchingNextPage) && loadingComponent}
      {!hasNextPage && endOfListComponent}
      <div ref={infiniteTriggerRef} />
    </Container>
  )
}

InfiniteScroll.propTypes = {
  children: PropTypes.func,
  endOfListComponent: PropTypes.node,
  loadingComponent: PropTypes.node,
  infiniteQueryHook: PropTypes.func.isRequired,
  infiniteQueryHookParams: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
}

InfiniteScroll.defaultProps = {
  children: null,
  infiniteQueryHookParams: [],
  endOfListComponent: (
    <p style={{ opacity: 0.5, paddingTop: 5 }}>
      You've reached the end of the list 👾
    </p>
  ),
  loadingComponent: <p>Loading</p>,
  style: {},
  onChange: () => {},
}

export default InfiniteScroll
