/**
 * @file Find users by name
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const FIND_USER_URL = '/api/user/find'

const findUser = async (name, accessToken) =>
  get(`${FIND_USER_URL}?query=${encodeURIComponent(name)}`, { accessToken })

const useFindUser = () => {
  const { auth } = useContext(RootContext)
  return useMutation(async ({ name }) => findUser(name, auth.accessToken))
}

export default useFindUser
