/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import InfiniteScroll from 'components/compound/InifiniteScroll'
import PendingPodVerificationCard from 'components/compound/Pods/Cards/PendingPodVerificationCard'
import useGetPendingSuperpodVerifications from 'hooks/query/useGetPendingSuperpodVerifications'
import useGetSuperpods from 'hooks/query/useGetSuperpods'
import SuperpodCard from 'components/compound/Pods/Cards/SuperpodCard'

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  padding: 0px 10px 8px 10px;
`

const Pods = () => (
  <>
    <Column>
      <Title>Pending Pod Verifications</Title>
      <InfiniteScroll
        style={{ padding: '2px 10px 0 10px' }}
        infiniteQueryHook={useGetPendingSuperpodVerifications}
        endOfListComponent={
          <p style={{ opacity: 0.5, paddingTop: 10 }}>
            Woohoo! Nothing to verify here!
          </p>
        }
      >
        {item => (
          <PendingPodVerificationCard
            key={item.id}
            id={item.id}
            name={item.user?.name}
            email={item.user?.email}
            profession={item.user?.profession}
            funFact={item.user?.funFact}
            birthday={item.user?.birthDate}
            superpod={item.value}
          />
        )}
      </InfiniteScroll>
    </Column>
    <Column style={{ marginLeft: 40 }}>
      <Title>All Superpods</Title>
      <InfiniteScroll
        style={{ padding: '2px 10px 0 10px' }}
        infiniteQueryHook={useGetSuperpods}
      >
        {item => (
          <SuperpodCard
            id={item.id}
            key={item.id}
            displayName={item.displayName}
            name={item.name}
          />
        )}
      </InfiniteScroll>
    </Column>
  </>
)

export default Pods
