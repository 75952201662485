/**
 * @file Active Event Cards
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Card from 'components/simple/Card'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

const StyledCard = styled(Card)`
  width: 335px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
`

const CoverImage = styled.div`
  height: 115px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ src }) => src});
  border-radius: 10px 10px 0 0;
`

const InfoContainer = styled.div`
  padding: 10px 15px 15px 15px;
`

const ParticipantInfo = styled.div`
  padding: 5px 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.Secondary};
`

const ActiveEventCard = ({
  id,
  startDate,
  title,
  picture,
  seatsLeft,
  maxParticipants,
}) => {
  const history = useHistory()

  const handleClick = () => {
    history.push(`/events/${id}`)
  }

  return (
    <StyledCard onClick={handleClick}>
      <CoverImage src={picture} />
      <ParticipantInfo>
        <h4>
          {maxParticipants - seatsLeft}/{maxParticipants}
        </h4>
      </ParticipantInfo>
      <InfoContainer>
        <h6>{dayjs(startDate).format('MMMM DD')}</h6>
        <h3>{title}</h3>
      </InfoContainer>
    </StyledCard>
  )
}

ActiveEventCard.propTypes = {
  id: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  picture: PropTypes.string,
  seatsLeft: PropTypes.number.isRequired,
  maxParticipants: PropTypes.number.isRequired,
}

ActiveEventCard.defaultProps = {
  picture: '',
}

export default ActiveEventCard
