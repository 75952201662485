/**
 * @file Header Component
 * @author Alwyn Tan
 */

import React, { useContext } from 'react'
import styled from 'styled-components'
import RootContext from 'RootContext'
import SearchBar from 'components/simple/SearchBar'

const height = '80px'

const Container = styled.div`
  height: ${() => height};
  position: fixed;
  top: 0;
  left: 80px;
  right: 0;
  padding: 0 30px 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.Primary};
  z-index: 999;
`

const ProfilePic = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ src }) => src});
`

const HeightPlaceholder = styled.div`
  height: ${() => height};
  min-height: ${() => height};
  width: 100%;
`

const Header = () => {
  const { auth } = useContext(RootContext)

  return (
    <>
      <Container>
        <SearchBar />
        {auth.user?.picture && <ProfilePic src={auth.user.picture} />}
      </Container>
      <HeightPlaceholder />
    </>
  )
}

Header.propTypes = {}

export default Header
