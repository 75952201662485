/**
 * @file Accepts superpod verification
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const POST_ACCEPT_SUPERPOD_VERIFICATION_URL =
  '/api/verification/accept-superpod'

const postAcceptSuperpodVerification = async (id, accessToken) =>
  post(POST_ACCEPT_SUPERPOD_VERIFICATION_URL, { id }, { accessToken })

const useAcceptSuperpodVerification = () => {
  const { auth } = useContext(RootContext)
  const queryClient = useQueryClient()

  // defaults party host to the auth, unless specified
  return useMutation(async id => {
    const toastID = toast.loading('Accepting Verification')
    const { verification } = await postAcceptSuperpodVerification(
      id,
      auth.accessToken
    )

    if (verification.status === 'ACCEPTED') {
      toast.success('Verification Accepted', { id: toastID })
      queryClient.invalidateQueries('pods-pending-verification')
    } else {
      toast.error('Error accepting verification', { id: toastID })
    }

    return { verification }
  })
}

export default useAcceptSuperpodVerification
