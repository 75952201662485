/**
 * @file General Input For Solo Party
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { useController } from 'react-hook-form'

const Container = styled.div`
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;

  > input {
    outline: none;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding-left: 5px;

    ::placeholder {
      opacity: 0.5;
    }
  }
`

const CountryFlag = styled.img`
  height: 15px;
  margin-top: 1px;
`

const Divider = styled.div`
  height: 20px;
  width: 2px;
  border-radius: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0px 10px;
`

const PhoneInput = ({ name, control, defaultValue, required, validate }) => {
  const countryCode = '+1'

  const { field } = useController({
    name,
    control,
    defaultValue,
    rules: { required, validate },
  })

  const transform = {
    input: value => value.replace(/[\s()-]+/g, '').replace(countryCode, ''),
    output: value => `${countryCode}${value.replace(/[\s()-]+/g, '')}`,
  }

  return (
    <Container>
      <CountryFlag src="https://catamphetamine.gitlab.io/country-flag-icons/3x2/US.svg" />
      <Divider />
      <p>{countryCode}</p>
      <NumberFormat
        format="(###) ### ####"
        mask=" "
        placeholder="(000) 000 0000"
        {...field}
        value={transform.input(field.value)}
        onChange={e => field.onChange(transform.output(e.target.value))}
      />
    </Container>
  )
}

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
}

PhoneInput.defaultProps = {
  defaultValue: '',
  required: false,
  validate: null,
}

export default PhoneInput
