/**
 * @file Gets an event query
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useQuery } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const GET_SUPERPOD_URL = '/api/pod/superpod'

const getSuperpod = async (id, accessToken) =>
  get(`${GET_SUPERPOD_URL}?id=${encodeURIComponent(id)}`, { accessToken })

const useGetSuperpod = id => {
  const { auth } = useContext(RootContext)
  return useQuery(['pod-superpod', id], async () =>
    getSuperpod(id, auth.accessToken)
  )
}

export default useGetSuperpod
