/**
 * @file Entry point
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import RootContext from 'RootContext'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

dayjs.extend(duration)

const theme = {
  Accent: '#DD3F4E',
  Primary: '#F7F7FA',
  Secondary: '#FFFFFF',
  Tertiary: '#F0F1F5',
  Text: '#000000',
  Success: '#52BB8B',
}

const queryClient = new QueryClient()

const Root = () => {
  const [auth, setAuth] = useState({})

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <RootContext.Provider value={{ auth, setAuth }}>
              <App />
            </RootContext.Provider>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
      <Toaster
        reverseOrder
        toastOptions={{
          style: {
            borderRadius: 200,
            backgroundColor: theme.Secondary,
            color: theme.Text,
          },
        }}
      />
    </React.StrictMode>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
