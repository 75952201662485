/**
 * @file App Routes
 * @author Alwyn Tan
 */

import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { Loader } from 'react-feather'
import useSilentLogin from 'hooks/query/useSilentLogin'
import RootContext from 'RootContext'
import Auth from 'pages/Auth'
import FourOhFour from 'pages/FourOhFour'
import Events from 'pages/Events'
import EventDetails from 'pages/EventDetails'
import Pods from 'pages/Pods'
import Home from 'pages/Home'
import SuperpodDetails from 'pages/SuperpodDetails'
import Page from 'components/templates/Page'

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${({ theme }) => theme.Primary}
  }
`

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

// Note: Everything is a protected route in App
const App = () => {
  const { auth } = useContext(RootContext)
  const { isLoading } = useSilentLogin()

  if (isLoading) {
    return (
      <>
        <GlobalStyle />
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      </>
    )
  }

  return (
    <>
      <GlobalStyle />
      {auth.user ? (
        <Page>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/events" component={Events} />
            <Route exact path="/events/:id" component={EventDetails} />
            <Route exact path="/pods" component={Pods} />
            <Route exact path="/pods/:id" component={SuperpodDetails} />
            <Route exact path="/404" component={FourOhFour} />
            <Redirect from="/login" to="/" />
            <Redirect from="*" to="/404" />
          </Switch>
        </Page>
      ) : (
        <Switch>
          <Route exact path="/login" component={Auth} />
          <Redirect from="*" to="/login" />
        </Switch>
      )}
    </>
  )
}

export default App
