/**
 * @file Solo Logo
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import logo from 'images/logo.png'

const Container = styled.div`
  position: relative;
  height: 40px;
  width: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${({ src }) => src});
`

const Logo = ({ style }) => <Container src={logo} style={style} />

Logo.propTypes = {
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

Logo.defaultProps = { style: {} }

export default Logo
