/**
 * @file Add a user to event modal
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'components/compound/Modal'
import Button from 'components/simple/Button'
import LookupUserInput from 'components/compound/Inputs/LookupUserInput'
import { useForm } from 'react-hook-form'
import useRegisterEvent from 'hooks/query/event/useRegisterEvent'
import toast from 'react-hot-toast'

const StyledModal = styled(Modal)`
  padding: 40px;
`

const Form = styled.form`
  > *:not(:last-child) {
    margin-bottom: 10px;
  }
`

const AddUserModal = ({ eventID, onClose }) => {
  const { control, formState, handleSubmit } = useForm({ mode: 'onChange' })

  const registerEventMutation = useRegisterEvent()

  const onSubmit = async ({ user }) => {
    const toastID = toast.loading('Registering User')
    try {
      await registerEventMutation.mutateAsync({
        eventID,
        userID: user.id,
      })
      toast.success('Registered user', { id: toastID })
      onClose()
    } catch (e) {
      toast.error('Error registering user', { id: toastID })
    }
  }

  return (
    <StyledModal onCancel={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3>Add a User</h3>
        <LookupUserInput name="user" control={control} required />
        <Button style={{ width: '100%' }} disabled={!formState.isValid}>
          Add
        </Button>
      </Form>
    </StyledModal>
  )
}

AddUserModal.propTypes = {
  eventID: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AddUserModal
