/**
 * @file Superpod Details
 * @author Alwyn Tan
 */

import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import SuperpodDetailsCard from 'components/compound/Pods/Cards/SuperpodDetailsCard'
import useGetSuperpod from 'hooks/query/useGetSuperpod'
import InfiniteScroll from 'components/compound/InifiniteScroll'
import useGetSuperpodMembers from 'hooks/query/useGetSuperpodMembers'
import SuperpodMembersTable from 'components/compound/Pods/SuperpodMembersTable'

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  padding: 0px 10px 8px 10px;
`

const ScrollView = styled.div`
  flex: 1;
  overflow: auto;
  padding: 2px 10px 0 10px;
`

const SuperpodDetails = () => {
  const { id } = useParams()
  const [users, setUsers] = useState([])

  const { data } = useGetSuperpod(id)
  const superpod = data?.superpod

  const handleInfiniteScrollChange = useCallback(pages => {
    if (pages) setUsers(pages.reduce((acc, page) => [...acc, ...page.data], []))
  }, [])

  if (!superpod) return null

  return (
    <>
      <Column>
        <Title>Superpod Details</Title>
        <ScrollView>
          <SuperpodDetailsCard
            picture={superpod.picture}
            displayName={superpod.displayName}
            name={superpod.name}
            description={superpod.description}
          />
        </ScrollView>
      </Column>
      <Column style={{ marginLeft: 40, flex: 1, width: 0 }}>
        <Title style={{ paddingBottom: 0 }}>Members</Title>
        <h4 style={{ padding: '0px 10px 8px 10px' }}>
          {superpod.userCount} member{superpod.userCount === 1 ? '' : 's'}
        </h4>
        <ScrollView>
          <SuperpodMembersTable users={users} />
          <InfiniteScroll
            style={{ padding: '2px 10px 0 10px' }}
            infiniteQueryHook={useGetSuperpodMembers}
            infiniteQueryHookParams={[superpod.name, superpod.id]}
            endOfListComponent={null}
            loadingComponent={null}
            onChange={handleInfiniteScrollChange}
          />
        </ScrollView>
      </Column>
    </>
  )
}

export default SuperpodDetails
