/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Card from 'components/simple/Card'
import Button from 'components/simple/Button'
import dayjs from 'dayjs'
import useAcceptSuperpodVerification from 'hooks/query/useAcceptSuperpodVerification'
import useRejectSuperpodVerification from 'hooks/query/useRejectSuperpodVerification'

const StyledCard = styled(Card)`
  padding: 20px;
  width: 350px;
  margin-bottom: 10px;

  strong {
    font-weight: 500;
  }
`

const Pod = styled.span`
  color: ${({ theme }) => theme.Accent};
`

const ButtonContainer = styled.div`
  margin-top: 20px;

  > * {
    margin-right: 10px;
  }
`

const PendingPodVerificationCard = ({
  id,
  name,
  email,
  profession,
  funFact,
  birthday,
  superpod,
}) => {
  const acceptMutation = useAcceptSuperpodVerification()
  const rejectMutation = useRejectSuperpodVerification()

  const handleAcceptClick = () => {
    acceptMutation.mutate(id)
  }

  const handleRejectClick = () => {
    rejectMutation.mutate(id)
  }

  return (
    <StyledCard>
      <h2>{name}</h2>
      <h4>
        Pod: <Pod>{superpod}</Pod>
      </h4>
      <p>
        <strong>Email:</strong> {email}
      </p>
      <p>
        <strong>Profession:</strong> {profession}
      </p>
      <p>
        <strong>Fun Fact:</strong> {funFact}
      </p>
      <p>
        <strong>Birthday:</strong> {dayjs(birthday).format('DD MMM YYYY')}
      </p>
      <ButtonContainer>
        <Button
          size="small"
          onClick={handleAcceptClick}
          disabled={acceptMutation.isLoading || rejectMutation.isLoading}
        >
          Accept
        </Button>
        <Button
          size="small"
          type="secondary"
          onClick={handleRejectClick}
          disabled={acceptMutation.isLoading || rejectMutation.isLoading}
        >
          Reject
        </Button>
      </ButtonContainer>
    </StyledCard>
  )
}

PendingPodVerificationCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  profession: PropTypes.string.isRequired,
  funFact: PropTypes.string.isRequired,
  birthday: PropTypes.string.isRequired,
  superpod: PropTypes.string.isRequired,
}

export default PendingPodVerificationCard
