/**
 * @file Auth Code Screen
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import useLogin from 'hooks/query/useLogin'
import Input from 'components/simple/Input'
import Logo from 'components/simple/Logo'

const Form = styled.form`
  width: 100%;
  max-width: 335px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    margin-bottom: 15px;
  }
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.Accent};
  outline: none;
  border: 0;
  border-radius: 10px;
  color: white;
  font-size: 1em;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const Footer = styled.h6`
  padding-top: 40px;
  opacity: 0.5;
  text-align: center;
`

const AuthCodeScreen = ({ onSubmit: onSubmitProp, phoneNumber }) => {
  const { control, formState, handleSubmit } = useForm({ mode: 'onChange' })

  const loginMutation = useLogin()

  const onSubmit = async ({ code }) => {
    loginMutation.mutate({ phoneNumber, code })
  }

  useEffect(() => {
    if (loginMutation.isSuccess) onSubmitProp(loginMutation.variables.code)
  }, [loginMutation.isSuccess, loginMutation.variables?.code, onSubmitProp])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Logo />
      <h2>Enter Your Code</h2>
      <Input
        required
        control={control}
        name="code"
        placeholder="Enter Code"
        type="Text"
        autoComplete="one-time-code"
        validate={value =>
          /^\d{4}$/.test(value) || 'Time must be in the future'
        }
        maxLength={4}
      />
      <Button disabled={!formState.isValid || loginMutation.isLoading}>
        <h3>Login</h3>
      </Button>
      <Footer>Solo © 2020</Footer>
    </Form>
  )
}

AuthCodeScreen.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
}

export default AuthCodeScreen
