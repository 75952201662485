/**
 * @file Removes a user from an event
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const UNREGISTER_EVENT_URL = '/api/event/unregister'

const postUnregisterEvent = async (eventID, userID, accessToken) =>
  post(UNREGISTER_EVENT_URL, { eventID, userID }, { accessToken })

const useUnregisterEvent = () => {
  const { auth } = useContext(RootContext)
  const queryClient = useQueryClient()

  // defaults party host to the auth, unless specified
  return useMutation(async ({ eventID, userID }) => {
    const { event } = await postUnregisterEvent(
      eventID,
      userID,
      auth.accessToken
    )
    queryClient.invalidateQueries(['event', eventID])
    return { event }
  })
}

export default useUnregisterEvent
