/**
 * @file 404 page not found
 * @author Alwyn Tan
 */

import React from 'react'

const FourOhFour = () => <h3>Page not found :(</h3>

export default FourOhFour
