/**
 * @file Hook to retrieve all superpod users
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useInfiniteQuery } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const GET_SUPERPOD_MEMBERS_URL = '/api/user/superpod'

const getSuperpodMembers = async (name, lastLoadedID, accessToken) => {
  const suffix = lastLoadedID
    ? `?lastLoadedID=${encodeURIComponent(lastLoadedID)}`
    : ''
  const { users: data, canLoadMore } = await get(
    `${GET_SUPERPOD_MEMBERS_URL}/${name}${suffix}`,
    { accessToken }
  )

  return { data, canLoadMore }
}

const useGetSuperpodMembers = name => {
  const { auth } = useContext(RootContext)
  return useInfiniteQuery(
    ['user-superpod-members', name],
    async ({ pageParam }) =>
      getSuperpodMembers(name, pageParam, auth.accessToken),
    {
      getNextPageParam: lastPage => {
        if (lastPage.canLoadMore)
          return lastPage.data[lastPage.data.length - 1].id
        return undefined
      },
    }
  )
}

export default useGetSuperpodMembers
