/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding-left: 10px;
`

const Home = () => (
  <Container>
    <h2>Home</h2>
    <p style={{ paddingTop: 5, opacity: 0.5 }}>Coming Soon</p>
  </Container>
)

export default Home
