/**
 * @file Gets an event query
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useQuery } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const GET_EVENT_URL = '/api/event'

const getEvent = async (id, accessToken) =>
  get(`${GET_EVENT_URL}/${id}`, { accessToken })

const useGetEvent = id => {
  const { auth } = useContext(RootContext)
  return useQuery(['event', id], async () => getEvent(id, auth.accessToken))
}

export default useGetEvent
